import { Inject, Injectable } from '@angular/core';
import { Events, NavController, AlertController, LoadingController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { UserService } from '../user/user.service';
import * as firebase from 'firebase';
import { map, first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfigService } from '../config/config.service';
import { CartService } from '../cart/cart.service';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import { LabelService } from '../label/label.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { Resale } from 'src/app/models/resale';
import { DOCUMENT } from "@angular/common";

declare var paytm: any;
declare var Razorpay;

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    loading: any;
    constructor(private events: Events,
        private storage: Storage,
        private afs: AngularFirestore,
        private userService: UserService,
        private navController: NavController,
        private router: Router,
        private modalController: ModalController,
        private loadingController: LoadingController,
        private alertController: AlertController,
        private configService: ConfigService,
        private logglyService: LogglyLoggerService,
        private labelService: LabelService,
        private angularFireStorage: AngularFireStorage,
        private cartService: CartService,
        private fbStorage: AngularFireStorage,
        private activatedRoute: ActivatedRoute,
        @Inject(DOCUMENT)
        private document: Document
        ) { }

    initializeSubscriptions() {
        this.events.subscribe('order:payWithRazorPay', (order, razorpayId, method) => {
            this.payWithRazorPay(order, razorpayId, method);
        });
        this.events.subscribe('order:payWithPaytm', (order) => {
            this.payWithPaytm(order);
        });
        this.events.subscribe('order:sendPaymentRequest', (orderId, userId) => {
            this.sendPaymentRequest(orderId, userId);
        });
        this.events.subscribe('order:payWithCash', (order) => {
            this.payWithCash(order);
        });
        this.events.subscribe('order:updatePaymentComplete', (orderId) => {
            this.updatePaymentComplete(orderId);
        });
        this.events.subscribe('order:completePaymentWithWallet', (order) => {
            this.completePaymentWithWallet(order);
        });

        //auto confirm...
        this.events.subscribe('order:ac_payWithRazorPay', (order, razorpayId, method) => {
            this.ac_payWithRazorPay(order, razorpayId, method);
        });
        this.events.subscribe('order:ac_payWithPaytm', (order) => {
            this.ac_payWithPaytm(order);
        });
        this.events.subscribe('order:ac_payWithCash', (order) => {
            this.ac_payWithCash(order);
        });
        this.events.subscribe('order:ac_completePaymentWithWallet', (order) => {
            this.ac_completePaymentWithWallet(order);
        });
        this.events.subscribe('order:ac_paytmWebInitiateTxn', (order) => {
            this.ac_paytmWebInitiateTxn(order);
        });

        this.events.subscribe('order:ac_completeUPIManualPayment', (order, paymentImg) => {
            this.ac_completeUPIManualPayment(order, paymentImg);
        });

        this.events.subscribe('order:ac_completeCustomOptionPayment', (order, response) => {
            this.ac_completeCustomOptionPayment(order, response);
        });

        this.events.subscribe('order:placeOrder', (products, listOfCommentImages, address, paymentObj) => {
            this.placeOrder(products, listOfCommentImages, address, paymentObj);
        });
        this.events.subscribe('order:autoConfirmPlaceOrder', (products, listOfCommentImages, address, paymentObj, isCodAvailableForCoupon) => {
            this.autoConfirmPlaceOrder(products, listOfCommentImages, address, paymentObj, isCodAvailableForCoupon);
        });

    }

    async payWithRazorPay(order: any, razorpayId: any, method: string) {
        try {
            const payableAmnt = order.partialPayment.status ? order.partialPayment.online.amount : (order.totalAmountToPaid - (order.walletAmount + order.cashbackAmount));
            await this.presentLoading();
            let createOrderInRazorpay = firebase.functions().httpsCallable('payments-razorpay_createOrder');
            const createOrderRes = await createOrderInRazorpay({amount: Math.round(payableAmnt * 100), orderDocId: order.id})
                const razorpayOrderId = createOrderRes.data && createOrderRes.data.orderId ? createOrderRes.data.orderId : '';
                console.log('razorpayOrderId', razorpayOrderId);
                if(razorpayOrderId) {
                    const storeInfo = await this.storage.get('storeInfo');
                    const options = {
                        order_id: razorpayOrderId,
                        description: this.configService.environment.razorpay.description,
                        currency: this.configService.environment.razorpay.currency,
                        key: razorpayId,
                        amount: Math.round(payableAmnt * 100),
                        name: storeInfo && storeInfo.storeName ? storeInfo.storeName : '',
                        image: this.configService.environment.razorpay.image,
                        prefill: {
                            method: method,
                            contact: this.userService.getPhoneNo() || '',
                            name: this.userService.getUserName() || '',
                            email: this.userService.getUserEmail() || 'xyz@gmail.com',
                        },
                        theme: this.configService.environment.razorpay.theme,
                        modal: {
                            ondismiss: async () => {
                                this.loading.dismiss();
                                this.presentFailureAlert('razorpay');
                            }
                        },
                        handler: async (handlerResponse) => {
                            console.log('handlerResponse', handlerResponse);
                            let verifySignature = firebase.functions().httpsCallable('payments-razorpay_verifySignature');
                            const apiBody = {
                                razorpay_payment_id: handlerResponse.razorpay_payment_id,
                                razorpay_order_id: handlerResponse.razorpay_order_id,
                                razorpay_signature: handlerResponse.razorpay_signature,
                            }

                            const txnRes = { paymentId: apiBody.razorpay_payment_id };
                            await this.updateRazorpayPaymentId(order.id, txnRes);

                            const verifySignatureRes = await verifySignature(apiBody)
                                console.log('verifySignatureRes', verifySignatureRes);
                                if(verifySignatureRes.data.signatureIsValid) {
                                    const paymentDetails = {
                                        order: order,
                                        mode: 'razorpay',
                                        txnRes,
                                        amount: options.amount
                                    }
                                    let saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-saveOrderPaymentDetails');
                                    saveOrderPaymentDetails(paymentDetails).then(async (res) => {
                                        if (res.data.status && res.data.status === 'success') {
                                            this.loading.dismiss();
                                            const paymentChatMsg = {
                                                author: 'user',
                                                createdAt: new Date(),
                                                isRead: true,
                                                orderId: order.orderId,
                                                published: true,
                                                status: 'PaymentMsg',
                                                type: 'order',
                                                paymentMode: 'razorpay'
                                            }
                                            await this.paymentChatMsgs(paymentChatMsg, order);
                                            this.presentAlert('Payment is successful!');
                                        }
                                    });
                                } else {
                                    this.loading.dismiss();
                                    this.presentFailureAlert('razorpay');
                                }
                        },
                    };
                    var razorpay = new Razorpay(options);
                    razorpay.open();
                    razorpay.on('payment.failed', async (response) => {
                        console.log('failure response', response);
                        this.loading.dismiss();
                        const paymentId = response.error && response.error.metadata && response.error.metadata.payment_id ? response.error.metadata.payment_id : '';
                        if(paymentId) {
                            const txnRes = { paymentId };
                            await this.updateRazorpayPaymentId(order.id, txnRes);
                        }
                        this.presentFailureAlert('razorpay');
                    });
                } else {
                    this.loading.dismiss();
                    this.presentFailureAlert('razorpay');
                }
        } catch (error) {
            console.dir(error);
            this.events.publish('order:paymentFailure');
        }
    }


    payWithPaytm(order: any) {
        const mobileNo = this.userService.getPhoneNo().slice(3);
        //// console.log(typeof mobileNo, mobileNo);
        const payableAmnt = order.partialPayment.status ? order.partialPayment.online.amount : (order.totalAmountToPaid - (order.walletAmount + order.cashbackAmount));
        let getCheckSum = firebase.functions().httpsCallable('payments-getCheckSumApi');
        getCheckSum({
            orderId: order.orderId.toString(),
            customerId: order.userId,
            phoneNo: mobileNo,
            txnAmount: payableAmnt.toString(),
        }).then((result) => {
            //// console.log('checksum:', result.data.checksum);

            const paytmParams: any = {
                MID: result.data.mid,
                ORDER_ID: order.orderId.toString(),
                CUST_ID: order.userId,
                CHANNEL_ID: this.configService.environment.paytm.CHANNEL_ID,
                TXN_AMOUNT: payableAmnt.toString(),
                WEBSITE: this.configService.environment.paytm.WEBSITE,
                CALLBACK_URL: "https://securegw-stage.paytm.in/theia/paytmCallback?ORDER_ID=" + order.orderId,
                INDUSTRY_TYPE_ID: this.configService.environment.paytm.INDUSTRY_TYPE_ID,
                MOBILE_NO: mobileNo,
                CHECKSUMHASH: result.data.checksum,
                ENVIRONMENT: this.configService.environment.paytm.ENVIRONMENT
            };
            let successCallback = async (response: any) => {
                if (response.STATUS == "TXN_SUCCESS") {
                    await this.presentLoading();
                    const paymentDetails = {
                        order: order,
                        mode: 'paytm',
                        txnRes: response,
                        amount: paytmParams.TXN_AMOUNT
                    }
                    let saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-saveOrderPaymentDetails');
                    saveOrderPaymentDetails(paymentDetails).then(async (res) => {
                        //// console.log('res of pd', res.data);
                        if (res.data.status && res.data.status === 'success') {
                            this.loading.dismiss();
                            const paymentChatMsg = {
                                author: 'user',
                                createdAt: new Date(),
                                isRead: true,
                                orderId: order.orderId,
                                published: true,
                                status: 'PaymentMsg',
                                type: 'order',
                                paymentMode: 'paytm'
                            }
                            await this.paymentChatMsgs(paymentChatMsg, order);
                            this.presentAlert('Payment is successful!');
                        }
                    });
                } else {
                    this.presentFailureAlert();
                }
            }

            let failureCallback = (error: any) => {
                this.presentFailureAlert();
            };

            paytm.startPayment(paytmParams, successCallback, failureCallback);

        }).catch(function(error) {
            var code = error.code;
            var message = error.message;
            var details = error.details;
            //// console.log("Error", code, message, details);
            this.presentFailureAlert();
        });
    }

    async sendPaymentRequest(orderId: any, userId: string) {
        try {
            const paymentReqMsg = {
                author: 'admin',
                createdAt: new Date(),
                isRead: true,
                orderId: orderId,
                published: true,
                status: 'PaymentRequest',
                type: 'order'
            }
            this.events.publish('chat:sendMsg', paymentReqMsg, userId);
            this.events.publish('order:sendPaymentRequestSuccess');
        } catch (error) {
            console.dir(error);
        }
    }
    async payWithCash(order: any) {
        try {
            let paymentWithCash = firebase.functions().httpsCallable('payments-paymentWithCash');
            paymentWithCash(order).then(async (res) => {
                if (res.data.status && res.data.status === 'success') {
                    await this.paymentChatMsgs('cash', order);
                    this.events.publish('order:modeSetToCashSuccess');
                } else {
                    this.presentFailureAlert();
                }
            });
        } catch (error) {
            console.dir(error);
        }
    }
    async updatePaymentComplete(orderId) {
        try {
            const orderRef = this.afs.collection('orders', ref => ref.where('orderId', '==', orderId));
            const orderData: any = await orderRef.snapshotChanges().pipe(
                map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return { id, ...data as {} };
                }))
            ).pipe(first()).toPromise();
            await this.afs.collection('orders').doc(orderData[0].id).update({
                payment: {
                    completed: true,
                    mode: 'cash'
                }
            });
            this.events.publish('order:updatePaymentCompleteSuccess');
        } catch (error) {
            console.dir(error);
        }
    }

    async completePaymentWithWallet(order: any) {
        try {
            let paymentWithWallet = firebase.functions().httpsCallable('payments-completePaymentWithWallet');
            paymentWithWallet(order).then(async (res) => {
                if (res.data.status && res.data.status === 'success') {
                    this.events.publish('order:completePaymentWithWalletSuccess');
                    const paymentChatMsg = {
                        author: 'user',
                        createdAt: new Date(),
                        isRead: true,
                        orderId: order.orderId,
                        published: true,
                        status: 'PaymentMsg',
                        type: 'order',
                        paymentMode: 'wallet'
                    }
                    await this.paymentChatMsgs(paymentChatMsg, order);

                } else {
                    this.presentFailureAlert();
                }
            });

        } catch (error) {
            console.dir(error);
        }
    }

    async paymentChatMsgs(chatObj, order) {
        const chatRef = this.afs.collection('chats').doc(order.userId).collection('messages', ref => ref
            .where('orderId', '==', order.orderId)
            .where('status', 'in', ['Confirmed', 'PaymentRequest']));
        const chatSnap = await chatRef.snapshotChanges().pipe(
            map(actions => actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return { id, ...data };
            }))
        ).pipe(first()).toPromise();
        for (let index = 0; index < chatSnap.length; index++) {
            //// console.log('msg id of do payment', chatSnap[index].id);
            await this.afs.collection('chats').doc(order.userId).collection('messages').doc(chatSnap[index].id).delete();
        }
        this.events.publish('chat:sendMsg', chatObj, order.userId);
    }

    //Auto confrim order functions
    async ac_payWithRazorPay(order: any, razorpayId: any, method: string) {
        try {
            await this.presentLoading();
            order['createdAt'] = new Date();
            const orderId: any = this.afs.collection('orders').ref.doc().id;

            const listOfCommentImages = { ...order['listOfCommentImages'] };
            delete order['listOfCommentImages'];

            if (order.uploadedDoc.uploads.length !== 0) {
                const uploads = await this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads);
                order.uploadedDoc['uploads'] = uploads;
            }
            if (!order.userName) {
                order.userName = order.address && order.address.name ? order.address.name : '';
            }

            await this.afs.collection('orders').doc(orderId).set(order);
            order['orderDocId'] = orderId;

            this.addCommentImgs(listOfCommentImages, orderId);
            this.clearProductsInCartIfAny(order.userId);

            const payableAmnt = order.partialPayment.status ? order.partialPayment.online.amount : (order.totalAmountToPaid - (order.walletAmount + order.cashbackAmount));

            //create order in razorpay
            let createOrderInRazorpay = firebase.functions().httpsCallable('payments-razorpay_createOrder');
            const createOrderRes = await createOrderInRazorpay({amount: Math.round(payableAmnt * 100), orderDocId: orderId})
                const razorpayOrderId = createOrderRes.data && createOrderRes.data.orderId ? createOrderRes.data.orderId : '';
                console.log('razorpayOrderId', razorpayOrderId);
                if(razorpayOrderId) {
                    const storeInfo = await this.storage.get('storeInfo');
                    const options = {
                        order_id: razorpayOrderId,
                        description: this.configService.environment.razorpay.description,
                        currency: this.configService.environment.razorpay.currency,
                        key: razorpayId,
                        amount: Math.round(payableAmnt * 100),
                        name: storeInfo && storeInfo.storeName ? storeInfo.storeName : '',
                        image: this.configService.environment.razorpay.image,
                        prefill: {
                            method: method,
                            contact: this.userService.getPhoneNo() || '',
                            name: this.userService.getUserName() || '',
                            email: this.userService.getUserEmail() || 'xyz@gmail.com',
                        },
                        theme: this.configService.environment.razorpay.theme,
                        modal: {
                            ondismiss: async () => {
                                this.loading.dismiss();
                                // await this.paymentFailedUpdate(order.orderDocId);
                                this.presentFailureAlert('razorpay');
                            }
                        },
                        handler: async (handlerResponse) => {
                            console.log('handlerResponse', handlerResponse);
                            let verifySignature = firebase.functions().httpsCallable('payments-razorpay_verifySignature');
                            const apiBody = {
                                razorpay_payment_id: handlerResponse.razorpay_payment_id,
                                razorpay_order_id: handlerResponse.razorpay_order_id,
                                razorpay_signature: handlerResponse.razorpay_signature,
                            }

                            const txnRes = { paymentId: apiBody.razorpay_payment_id };
                            await this.updateRazorpayPaymentId(order.orderDocId, txnRes);

                            const verifySignatureRes = await verifySignature(apiBody)
                                console.log('verifySignatureRes', verifySignatureRes);
                                if(verifySignatureRes.data.signatureIsValid) {
                                    const paymentDetails = {
                                        order: order,
                                        mode: 'razorpay',
                                        txnRes,
                                        amount: options.amount
                                    }
                                    let ac_saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-ac_saveOrderPaymentDetails');
                                    ac_saveOrderPaymentDetails(paymentDetails).then(async (res) => {
                                        if (res.data.status && res.data.status === 'success') {
                                            this.loading.dismiss();
                                            this.presentAlert('Order has been placed successfully!');
                                        } else {
                                            // await this.paymentFailedUpdate(order.orderDocId);
                                            this.loading.dismiss();
                                            this.presentFailureAlert('razorpay');
                                        }
                                    })
                                } else {
                                    this.loading.dismiss();
                                    // await this.paymentFailedUpdate(order.orderDocId);
                                    this.presentFailureAlert('razorpay');
                                }
                        },
                    };
                    var razorpay = new Razorpay(options);
                    razorpay.open();
                    razorpay.on('payment.failed', async (response) => {
                        console.log('inside payment.failed');
                        if (!response.error && response.error.description && response.error.description.includes('undefined')) {
                            console.log('failure response', response);
                            this.loading.dismiss();
                            const paymentId = response.error && response.error.metadata && response.error.metadata.payment_id ? response.error.metadata.payment_id : '';
                            if(paymentId) {
                                const txnRes = { paymentId };
                                await this.updateRazorpayPaymentId(orderId, txnRes);
                            }
                            // await this.paymentFailedUpdate(order.orderDocId);
                            this.presentFailureAlert('razorpay');
                        }
                    });
                } else {
                    this.loading.dismiss();
                    // await this.paymentFailedUpdate(order.orderDocId);
                    this.presentFailureAlert('razorpay');
                }
        } catch (error) {
            console.dir(error);
        }
    }

    async updateRazorpayPaymentId(orderId: string, txnRes) {
        await this.afs.collection('orders').doc(orderId).update({
            'payment.details': txnRes,
            'payment.mode': 'razorpay'
        });
    }

    async paymentFailedUpdate(orderId: string) {
        try {
            await this.afs.collection('orders').doc(orderId).update({
                'payment.completed': false,
                'payment.status': 'failed',
                walletAmount: 0,
                cashbackAmount: 0,
                extraChargeOnPayment: {
                    charge: 0
                },
                status: 'Pending'
            });
        } catch (error) {
            console.dir(error);
        }
    }

    async ac_payWithPaytm(order: any) {
        await this.presentLoading();
        const mobileNo = this.userService.getPhoneNo().slice(3);

        const orderId: any = this.afs.collection('orders').ref.doc().id;
        order['createdAt'] = new Date();

        const listOfCommentImages = { ...order['listOfCommentImages'] };
        delete order['listOfCommentImages'];

        if (order.uploadedDoc.uploads.length !== 0) {
            const uploads = await this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads);
            order.uploadedDoc['uploads'] = uploads;
        }

        await this.afs.collection('orders').doc(orderId).set(order);
        order['orderDocId'] = orderId;

        const orderRefId = order.orderDocId;
        this.addCommentImgs(listOfCommentImages, orderId);

        const payableAmnt = order.partialPayment.status ? order.partialPayment.online.amount : (order.totalAmountToPaid - (order.walletAmount + order.cashbackAmount));
        let getCheckSum = firebase.functions().httpsCallable('payments-getCheckSumApi');
        getCheckSum({
            orderId: orderRefId.toString(),
            customerId: order.userId,
            phoneNo: mobileNo,
            txnAmount: payableAmnt.toString(),
        }).then((result) => {
            const paytmParams: any = {
                MID: result.data.mid,
                ORDER_ID: orderRefId.toString(),
                CUST_ID: order.userId,
                CHANNEL_ID: this.configService.environment.paytm.CHANNEL_ID,
                TXN_AMOUNT: payableAmnt.toString(),
                WEBSITE: this.configService.environment.paytm.WEBSITE,
                CALLBACK_URL: "https://securegw-stage.paytm.in/theia/paytmCallback?ORDER_ID=" + orderRefId.toString(),
                INDUSTRY_TYPE_ID: this.configService.environment.paytm.INDUSTRY_TYPE_ID,
                MOBILE_NO: mobileNo,
                CHECKSUMHASH: result.data.checksum,
                ENVIRONMENT: this.configService.environment.paytm.ENVIRONMENT
            };
            let successCallback = async (response: any) => {
                if (response.STATUS == "TXN_SUCCESS") {
                    const paymentDetails = {
                        order: order,
                        mode: 'paytm',
                        txnRes: response,
                        amount: paytmParams.TXN_AMOUNT
                    }
                    let ac_saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-ac_saveOrderPaymentDetails');
                    ac_saveOrderPaymentDetails(paymentDetails).then(async (res) => {
                        if (res.data.status && res.data.status === 'success') {
                            this.loading.dismiss();
                            this.presentAlert('Order has been placed successfully!');
                            this.clearProductsInCartIfAny(order.userId);
                        } else {
                            await this.paymentFailedUpdate(order.orderDocId);
                            this.loading.dismiss();
                            this.presentFailureAlert();
                        }
                    });
                } else {
                    await this.paymentFailedUpdate(order.orderDocId);
                    this.loading.dismiss();
                    this.presentFailureAlert();
                }
            }

            let failureCallback = async (error: any) => {
                await this.paymentFailedUpdate(order.orderDocId);
                this.loading.dismiss();
                this.presentFailureAlert();
            };

            paytm.startPayment(paytmParams, successCallback, failureCallback);

        }).catch(function(error) {
            var code = error.code;
            var message = error.message;
            var details = error.details;
            //// console.log("Error", code, message, details);
            this.presentFailureAlert();
        });
    }

    async ac_completePaymentWithWallet(order: any) {
        try {
            const orderId: any = this.afs.collection('orders').ref.doc().id;
            order['createdAt'] = new Date();

            const listOfCommentImages = { ...order['listOfCommentImages'] };
            delete order['listOfCommentImages'];

            if (order.uploadedDoc.uploads.length !== 0) {
                const uploads = await this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads);
                order.uploadedDoc['uploads'] = uploads;
            }

            await this.afs.collection('orders').doc(orderId).set(order);
            order['orderDocId'] = orderId;

            this.addCommentImgs(listOfCommentImages, orderId);

            let orderPaymentWithWallet = firebase.functions().httpsCallable('wallet-orderPaymentWithWallet');
            orderPaymentWithWallet(order).then(async (res) => {
                if (res.data.status && res.data.status === 'success') {
                    this.events.publish('order:ac_completePaymentWithWalletSuccess');
                    this.clearProductsInCartIfAny(order.userId);
                } else {
                    await this.paymentFailedUpdate(order.orderDocId);
                    this.presentFailureAlert();
                }
            });

        } catch (error) {
            console.dir(error);
            this.presentFailureAlert();
        }
    }

    async clearProductsInCartIfAny(userId) {
        this.storage.get('buyNowOrder').then(async (val) => {
            if (!val) {
                const cartRef = this.afs.collection('users').doc(userId).collection('cart');
                const cartData: any = await cartRef.snapshotChanges().pipe(
                    map(actions => actions.map(a => {
                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return { id, ...data };
                    }))
                ).pipe(first()).toPromise();
                for (let i = 0; i < cartData.length; i++) {
                    await this.cartService.deleteCartProduct(cartData[i].id);
                }
                this.storage.set('cart', []);
            }
        });
    }

    async ac_payWithCash(order: any) {
        try {
            order['payment'] = {
                completed: false,
                mode: 'cash',
                details: {
                    amount: order.totalAmountToPaid - order.walletAmount - order.cashbackAmount
                }
            };

            let listOfCommentImages = order['listOfCommentImages'];

            let ac_paymentWithCash = firebase.functions().httpsCallable('payments-ac_paymentWithCash');
            ac_paymentWithCash(order).then(async (res) => {
                if (res.data.status && res.data.status === 'success') {
                    const orderId = res.data.orderDocId;
                    if (order.uploadedDoc.uploads.length !== 0) {
                        const uploads = await this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads);
                        await this.afs.collection('orders').doc(orderId).update({
                            "uploadedDoc.uploads": uploads
                        });
                    }
                    this.addCommentImgs(listOfCommentImages, orderId)
                    this.events.publish('order:ac_modeSetToCashSuccess');
                    this.clearProductsInCartIfAny(order.userId);
                } else {
                    this.presentFailureAlert();
                }
            });
        } catch (error) {
            console.dir(error);
        }
    }

    // paytm web...
    async ac_paytmWebInitiateTxn(order) {
        try {
            const orderRefId = this.afs.collection('orders').ref.doc().id;
            const initiateParams = {
                orderId: orderRefId.toString(),
                customerId: order.userId,
                txnAmount: (order.totalAmountToPaid - order.walletAmount - order.cashbackAmount).toString()
            };
            let initiateTxn = firebase.functions().httpsCallable('payments-initiateTxnApi');
            initiateTxn(initiateParams).then(res => {
                //// console.log('token...', res.data);
                this.events.publish('order:initiateTxnSuccess', res.data, orderRefId.toString());
            });
        } catch (error) {
            console.dir(error);
        }
    }

    async presentLoading() {
        console.log('loading');
        this.loading = await this.loadingController.create({
            message: 'Please Wait...',
        });
        await this.loading.present();
    }

    async ac_payWithUPIManual(order: any) {
        try {
            await this.presentLoading();

            const orderId: any = this.afs.collection('orders').ref.doc().id;
            order['createdAt'] = new Date();
            order['status'] = 'Pending';

            const listOfCommentImages = { ...order['listOfCommentImages'] };
            delete order['listOfCommentImages'];

            if (order.uploadedDoc.uploads.length !== 0) {
                const uploads = await this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads);
                order.uploadedDoc['uploads'] = uploads;
            }

            await this.afs.collection('orders').doc(orderId).set(order);
            order['orderDocId'] = orderId;

            this.addCommentImgs(listOfCommentImages, orderId);
            this.clearProductsInCartIfAny(order.userId);
            this.loading.dismiss();
            return({order});
        } catch (error) {
            console.dir(error);
            error['location'] = 'order-service:ac_payWithUPIManual';
            this.logglyService.log(error);
        }
    }

    async ac_completeUPIManualPayment(order, paymentImg) {
        try {
            await this.presentLoading();
            let orderId = '';
            if (order.hasOwnProperty('orderDocId')) {
                orderId = order.orderDocId;
            } else {
                const orderRef = this.afs.collection('orders', ref => ref.where('orderId', '==', order.orderId));
                const orderData: any = await orderRef.snapshotChanges().pipe(
                    map(actions => actions.map(a => {
                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return { id, ...data as {} };
                    }))
                ).pipe(first()).toPromise();
                orderId = orderData[0].id;
            }
            const imgRef: any = this.fbStorage.ref(`upiManualPayment/${orderId}/image/` + new Date().getTime().toString() + '.png');
            await imgRef.putString(paymentImg, 'data_url');
            const downloadURL = await imgRef.getDownloadURL().pipe(first()).toPromise();
            const payment = {
                completed: true,
                mode: 'upiManual',
                screenshot: downloadURL,
                status: 'completed'
            }
            await this.afs.collection('orders').doc(orderId).update({
                payment: payment,
                status: 'Confirmed',
                scheduledDate: order.scheduledDate ? new Date(order.scheduledDate) : '',
                scheduledTime: order.scheduledTime
            });
            this.loading.dismiss();
            this.presentAlert(this.labelService.labels['ORDER_SERVICE']['order_has_been_placed_successfully']);
            if (order.walletAmount > 0 || order.cashbackAmount > 0) {
                const walletObj = {
                    walletAmount: order.walletAmount,
                    cashbackAmount: order.cashbackAmount,
                    orderId: orderId,
                    userId: order.userId
                }
                let updateUserWalletAmount = firebase.functions().httpsCallable('payments-updateUserWalletAmount');
                updateUserWalletAmount(walletObj).then(async (res) => {
                    console.log(res);
                });
            }
        } catch (error) {
            console.dir(error);
            error['location'] = 'order-service:ac_completeUPIManualPayment';
            this.logglyService.log(error);
        }
    }

    // async ac_completeUPIManualPayment(order, paymentImg) {
    //     try {
    //         await this.presentLoading();
    //         order['createdAt'] = new Date();
    //         order['status'] = 'Confirmed';
    //         order['scheduledDate'] = order.scheduledDate ? new Date(order.scheduledDate) : '';
    //         order['scheduledTime'] = order.scheduledTime;
    //         let orderId = '';
    //         if (!order.orderId) {
    //             orderId = this.afs.collection('orders').ref.doc().id;

    //             if (order.uploadedDoc.uploads.length !== 0) {
    //                 const uploads = await this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads);
    //                 order.uploadedDoc['uploads'] = uploads;
    //             }
    //         } else {
    //             const orderRef = this.afs.collection('orders', ref => ref.where('orderId', '==', order.orderId));
    //             const orderData: any = await orderRef.snapshotChanges().pipe(
    //                 map(actions => actions.map(a => {
    //                     const data = a.payload.doc.data();
    //                     const id = a.payload.doc.id;
    //                     return { id, ...data as {} };
    //                 }))
    //             ).pipe(first()).toPromise();
    //             orderId = orderData[0].id;
    //         }
    //         const imgRef: any = this.angularFireStorage.ref(`upiManualPayment/${orderId}/image/` + new Date().getTime().toString() + '.png');
    //         await imgRef.putString(paymentImg, 'data_url');
    //         const downloadURL = await imgRef.getDownloadURL().pipe(first()).toPromise();
    //         order['payment'] = {
    //             completed: true,
    //             mode: 'upiManual',
    //             screenshot: downloadURL,
    //             status: 'completed'
    //         }
    //         if (!order.orderId) {

    //             const listOfCommentImages = { ...order['listOfCommentImages'] };
    //             delete order['listOfCommentImages'];

    //             await this.afs.collection('orders').doc(orderId).set(order);
    //             this.addCommentImgs(listOfCommentImages, orderId);
    //             this.clearProductsInCartIfAny(order.userId);
    //         } else {
    //             await this.afs.collection('orders').doc(orderId).update(order);
    //         }
    //         this.loading.dismiss();
    //         this.presentAlert(this.labelService.labels['ORDER_SERVICE']['order_has_been_placed_successfully']);
    //         if (order.walletAmount > 0 || order.cashbackAmount > 0) {
    //             const walletObj = {
    //                 walletAmount: order.walletAmount,
    //                 cashbackAmount: order.cashbackAmount,
    //                 orderId: orderId,
    //                 userId: order.userId
    //             }
    //             let updateUserWalletAmount = firebase.functions().httpsCallable('payments-updateUserWalletAmount');
    //             updateUserWalletAmount(walletObj).then(async (res) => {
    //                 console.log(res);
    //             });
    //         }
    //     } catch (error) {
    //         console.dir(error);
    //         error['location'] = 'order-service:ac_completeUPIManualPayment';
    //         this.logglyService.log(error);
    //     }

    // }

    async presentAlert(msg: string) {
        const alert = await this.alertController.create({
            message: msg,
            backdropDismiss: false,
            buttons: [{
                text: 'OK',
                handler: () => {
                    this.modalController.dismiss();
                    this.navController.navigateRoot(['order-successful']);
                }
            }]
        });


        await alert.present();
    }
    async presentFailureAlert(mode?) {
        if (this.loading) {
            this.loading.dismiss();
        }
        const alert = await this.alertController.create({
            message: mode === 'razorpay' ? 'We are verifying your payment status. It may take upto 15 minutes. Please check order details from my orders for further information'
             : 'Payment is failed! Any amount debited will be refunded in 4 - 5 working days.',
            backdropDismiss: false,
            buttons: [{
                text: 'Ok',
                handler: async () => {
                    const isExternalOrder = await this.storage.get('isExternalOrder');
                    if (isExternalOrder) {
                        this.navController.navigateRoot(['order-failure']);
                    } else{
                        this.navController.navigateRoot(['user-order-history']);
                    }
                }
            }]
        });

        await alert.present();
    }

    async addCommentImgs(listOfCommentImages, orderId) {
        if (Object.keys(listOfCommentImages).length !== 0) {
            for (let pid of Object.keys(listOfCommentImages)) {
                const imgs = listOfCommentImages[pid];
                for (const img of imgs) {
                    const imgRef: any = this.fbStorage.ref(`ordersCommentImgs/${orderId}/images/${pid}/` + new Date().getTime() + '.png');
                    await imgRef.putString(img, 'data_url');
                }
            }
        }
    }

    async placeOrder(products, listOfCommentImages, address, paymentObj) {
        try {
            const finalProducts = products.map(({cartUpdateTimer, priceSlabs, priceStatus, stopWhenNoQty, templateId, totalQty, ...keepAttrs}) => keepAttrs)
            paymentObj['products'] = finalProducts;
            paymentObj['address'] = address;
            let orderObj = await this.getOrderObject(paymentObj);
            const orderId: any = this.afs.collection('orders').ref.doc().id;
            if (orderObj.uploadedDoc.uploads.length !== 0) {
                const uploads = await this.getUploadedDocUrls(orderId, orderObj.uploadedDoc.uploads);
                orderObj.uploadedDoc['uploads'] = uploads;
            }
            console.log('orderObj:', orderObj);
            if (!orderObj.userName) {
                orderObj.userName = orderObj.address && orderObj.address.name ? orderObj.address.name : '';
            }
            await this.afs.collection('orders').doc(orderId).set(orderObj);
            if (address.lat) {
                await this.afs.collection('orders').doc(orderId).update({
                    deliveryLatLng: {
                        lat: address.lat,
                        lng: address.lng
                    }
                });
            }

            this.addCommentImgs(listOfCommentImages, orderId);

            this.clearProductsInCartIfAny(orderObj.userId);
            this.events.publish('order:orderSuccessfullyPlaced');

        } catch (error) {
            console.dir(error);
        }
    }

    async autoConfirmPlaceOrder(products, listOfCommentImages, address, paymentObj, isCodAvailableForCoupon) {
        try {
            const finalProducts = products.map(({cartUpdateTimer, priceSlabs, priceStatus, stopWhenNoQty, templateId, totalQty, ...keepAttrs}) => keepAttrs)
            paymentObj['products'] = finalProducts;
            paymentObj['address'] = address;
            let orderObj = await this.getOrderObject(paymentObj);
            orderObj['status'] = 'Pending';
            orderObj['payment']['status'] = 'pending';

            if (address.lat) {
                orderObj['deliveryLatLng'] = {
                    lat: address.lat,
                    lng: address.lng
                };
            }

            orderObj['listOfCommentImages'] = listOfCommentImages;
            orderObj['isCodAvailableForCoupon'] = isCodAvailableForCoupon;

            this.events.publish('order:autoConfirmPlaceOrderSuccess', orderObj);
        } catch (error) {
            console.dir(error);
        }
    }

    async getOrderObject(paymentObj: any) {
        let region = await this.storage.get('region');
        let userId = await this.storage.get('uid');
        return {
            ...paymentObj,
            orderId: null,
            status: 'Pending',
            createdAt: new Date(),
            payment: {
                completed: false,
                mode: null,
                details: null
            },
            userId: userId,
            msgId: this.afs.collection('chats').doc(userId).collection('messages').ref.doc().id,
            userName: this.userService.getUserName(),
            discount: 0,
            region: region && region.name ? region.name : '',
            vendorId: region && region.vendorId ? region.vendorId : '',
        }
    }
    async getUploadedDocUrls(orderId: string, uploads: any) {
        return new Promise(async (resolve, reject) => {
            for (const img of uploads) {
                const imgRef: any = this.angularFireStorage.ref(`orders/${orderId}/uploadedDoc/` + new Date().getTime() + '.png');
                await imgRef.putString(img.url, 'data_url');
                img.url = await imgRef.getDownloadURL().pipe(first()).toPromise();
            }
            resolve(uploads);
        });
    }

    async saveResaleData(resale: Resale, orderId: string) {
        return new Promise(async (resolve, reject) => {
            try {
                await this.afs.collection('orders').doc(orderId).update({ resale });
                resolve(true);
            } catch (error) {
                console.dir(error);
                error['location'] = 'user-service:saveResaleData';
                this.logglyService.log(error);
                resolve(false);
            }
        });
    }


    async ac_completeCustomOptionPayment(order, userResponse: { textDetails: string, image: string, optionName: string }) {
        try {
            await this.presentLoading();
            order['createdAt'] = new Date();
            order['status'] = 'Confirmed';
            order['scheduledDate'] = order.scheduledDate ? new Date(order.scheduledDate) : '';
            order['scheduledTime'] = order.scheduledTime;
            let orderId = '';
            if (!order.orderId) {
                orderId = this.afs.collection('orders').ref.doc().id;

                if (order.uploadedDoc.uploads.length !== 0) {
                    const uploads = await this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads);
                    order.uploadedDoc['uploads'] = uploads;
                }
            } else {
                const orderRef = this.afs.collection('orders', ref => ref.where('orderId', '==', order.orderId));
                const orderData: any = await orderRef.snapshotChanges().pipe(
                    map(actions => actions.map(a => {
                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return { id, ...data as {} };
                    }))
                ).pipe(first()).toPromise();
                orderId = orderData[0].id;
            }

            let downloadURL = '';
            if (userResponse.image) {
                const imgRef: any = this.fbStorage.ref(`customPayment/${orderId}/image/` + new Date().getTime().toString() + '.png');
                await imgRef.putString(userResponse.image, 'data_url');
                downloadURL = await imgRef.getDownloadURL().pipe(first()).toPromise();
            }

            order['payment'] = {
                completed: true,
                mode: 'custom',
                optionName: userResponse.optionName,
                screenshot: downloadURL,
                textDetails: userResponse.textDetails,
                status: 'completed'
            }
            if (!order.orderId) {

                const listOfCommentImages = { ...order['listOfCommentImages'] };
                delete order['listOfCommentImages'];

                await this.afs.collection('orders').doc(orderId).set(order);
                this.addCommentImgs(listOfCommentImages, orderId);
                this.clearProductsInCartIfAny(order.userId);
            } else {
                await this.afs.collection('orders').doc(orderId).update(order);
            }
            this.loading.dismiss();
            this.presentAlert(this.labelService.labels['ORDER_SERVICE']['order_has_been_placed_successfully']);
            if (order.walletAmount > 0 || order.cashbackAmount > 0) {
                const walletObj = {
                    walletAmount: order.walletAmount,
                    cashbackAmount: order.cashbackAmount,
                    orderId: orderId,
                    userId: order.userId
                }
                let updateUserWalletAmount = firebase.functions().httpsCallable('payments-updateUserWalletAmount');
                updateUserWalletAmount(walletObj).then(async (res) => {
                    console.log(res);
                });
            }
        } catch (error) {
            console.dir(error);
            error['location'] = 'order-service:ac_completeCustomOptionPayment';
            this.logglyService.log(error);
        }

    }

    async ac_payWithStripe(data: any) {
        try {
            const order = data.order;
            await this.presentLoading();
            const orderId: any = this.afs.collection('orders').ref.doc().id;
            order['createdAt'] = new Date();

            const listOfCommentImages = { ...order['listOfCommentImages'] };
            delete order['listOfCommentImages'];

            if (order.uploadedDoc.uploads.length !== 0) {
                const uploads = await this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads);
                order.uploadedDoc['uploads'] = uploads;
            }

            await this.afs.collection('orders').doc(orderId).set(order);
            order['orderDocId'] = orderId;

            this.addCommentImgs(listOfCommentImages, orderId);
            this.clearProductsInCartIfAny(order.userId);

            const payableAmnt = order.partialPayment.status ? order.partialPayment.online.amount : (order.totalAmountToPaid - (order.walletAmount + order.cashbackAmount));
            const stripeData = {
                amount: payableAmnt * 100,
                currency: this.configService.environment.currencyCode,
                token: data.token
            }
            let payWithStripe = firebase.functions().httpsCallable('payments-payWithStripe');
            payWithStripe(stripeData).then(async (res) => {
                console.log('res of pay with stripe', res);
                if (res.data.status && res.data.status === 'success') {
                    const paymentDetails = {
                        order: order,
                        mode: 'stripe',
                        txnRes: res.data.txnRes,
                        amount: payableAmnt
                    };
                    let ac_saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-ac_saveOrderPaymentDetails');
                    ac_saveOrderPaymentDetails(paymentDetails).then(async (res) => {
                        if (res.data.status && res.data.status === 'success') {
                            this.loading.dismiss();
                            this.presentAlert(this.labelService.labels['ORDER_SERVICE']['order_has_been_placed_successfully']);
                        } else {
                            await this.paymentFailedUpdate(order.orderDocId);
                            this.loading.dismiss();
                            this.presentFailureAlert();
                        }
                    });
                } else {
                    await this.paymentFailedUpdate(order.orderDocId);
                    this.loading.dismiss();
                    this.presentFailureAlert();
                }
            });

        } catch (error) {
            console.dir(error);
            error['location'] = 'order-service:ac_payWithRazorPay';
            this.logglyService.log(error);
        }
    }

    async payWithStripe(data: any) {
        try {
            await this.presentLoading();
            const order = data.order;
            const payableAmnt = order.partialPayment.status ? order.partialPayment.online.amount : (order.totalAmountToPaid - (order.walletAmount + order.cashbackAmount));
            const stripeData = {
                amount: payableAmnt * 100,
                currency: this.configService.environment.currencyCode,
                token: data.token
            }
            let payWithStripe = firebase.functions().httpsCallable('payments-payWithStripe');
            payWithStripe(stripeData).then(async (res) => {
                console.log('res of pay with stripe', res);
                if (res.data.status && res.data.status === 'success') {
                    const paymentDetails = {
                        order: order,
                        mode: 'stripe',
                        txnRes: res.data.txnRes,
                        amount: payableAmnt
                    };
                    let saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-saveOrderPaymentDetails');
                    saveOrderPaymentDetails(paymentDetails).then(async (res) => {
                        if (res.data.status && res.data.status === 'success') {
                            const paymentChatMsg = {
                                author: 'user',
                                createdAt: new Date(),
                                isRead: true,
                                orderId: order.orderId,
                                published: true,
                                status: 'PaymentMsg',
                                type: 'order',
                                paymentMode: 'stripe'
                            }
                            await this.paymentChatMsgs(paymentChatMsg, order);

                            this.loading.dismiss();
                            this.presentAlert(this.labelService.labels['ORDER_SERVICE']['payment_is_successful']);
                        } else {
                            this.loading.dismiss();
                            this.presentFailureAlert();
                        }
                    });
                } else {
                    this.loading.dismiss();
                    this.presentFailureAlert();
                }
            });

        } catch (error) {
            console.dir(error);
            error['location'] = 'order-service:payWithRazorPay';
            this.logglyService.log(error);
            this.loading.dismiss();
            this.presentFailureAlert();
        }
    }

    async ac_prepareOrderForPayment(order: any, paymentType?: string){
        try {
            await this.presentLoading();
            const orderId: any = this.afs.collection('orders').ref.doc().id;
            order['createdAt'] = new Date();

            const listOfCommentImages = { ...order['listOfCommentImages'] };
            delete order['listOfCommentImages'];

            if(order.uploadedDoc.uploads.length !== 0) {
                const uploads = await this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads);
                order.uploadedDoc['uploads'] = uploads;
            }

            await this.afs.collection('orders').doc(orderId).set(order);
            order['orderDocId'] = orderId;

            this.addCommentImgs(listOfCommentImages, orderId);
            this.clearProductsInCartIfAny(order.userId);

            const payableAmnt = order.partialPayment.status ? order.partialPayment.online.amount : (order.totalAmountToPaid - (order.walletAmount + order.cashbackAmount));
            if(paymentType && (paymentType === 'paypal' || paymentType === 'cashfree' || paymentType === 'ccAvenue')) {
                this.loading.dismiss();
            }
            return {payableAmnt, order};
        } catch (error) {
            console.dir(error);
            error['location'] = 'order-service:prepareOrderForPayment';
            this.logglyService.log(error);
        }
    }

    async ac_payWithPaypal(order, txnRes, payableAmnt) {
        try {
            await this.presentLoading();
            const paymentDetails = {
                order: order,
                mode: 'paypal',
                txnRes: txnRes,
                amount: payableAmnt
            };
            let ac_saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-ac_saveOrderPaymentDetails');
                    ac_saveOrderPaymentDetails(paymentDetails).then(async (res) => {
                        if (res.data.status && res.data.status === 'success') {
                            this.loading.dismiss();
                            this.presentAlert(this.labelService.labels['ORDER_SERVICE']['order_has_been_placed_successfully']);
                        } else {
                            await this.paymentFailedUpdate(order.orderDocId);
                            this.loading.dismiss();
                            this.presentFailureAlert();
                        }
                    });
        } catch (error) {
            console.log(error);
        }
    }

    async payWithPaypal(order, txnRes, payableAmnt) {
        try {
            await this.presentLoading();

            const paymentDetails = {
                order: order,
                mode: 'paypal',
                txnRes: txnRes,
                amount: payableAmnt
            };
            let saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-saveOrderPaymentDetails');
            saveOrderPaymentDetails(paymentDetails).then(async (res) => {
                if (res.data.status && res.data.status === 'success') {
                    const paymentChatMsg = {
                        author: 'user',
                        createdAt: new Date(),
                        isRead: true,
                        orderId: order.orderId,
                        published: true,
                        status: 'PaymentMsg',
                        type: 'order',
                        paymentMode: 'paypal'
                    }
                    await this.paymentChatMsgs(paymentChatMsg, order);

                    this.loading.dismiss();
                    this.presentAlert(this.labelService.labels['ORDER_SERVICE']['payment_is_successful']);
                } else {
                    this.loading.dismiss();
                    this.presentFailureAlert();
                }
            });


        } catch (error) {
            console.dir(error);
            error['location'] = 'order-service:payWithRazorPay';
            this.logglyService.log(error);
            this.loading.dismiss();
            this.presentFailureAlert();
        }
    }

    async ac_payWithCashfree(order, txnRes, payableAmnt) {
        try {
            //await this.presentLoading();
            const paymentDetails = {
                order: order,
                mode: 'cashfree',
                txnRes: txnRes,
                amount: payableAmnt
            };
            console.log('paymentDetails:', paymentDetails);
            let ac_saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-ac_saveOrderPaymentDetails');
                    ac_saveOrderPaymentDetails(paymentDetails).then(async (res) => {
                        if (res.data.status && res.data.status === 'success') {
                            this.loading.dismiss();
                            this.presentAlert(this.labelService.labels['ORDER_SERVICE']['order_has_been_placed_successfully']);
                        } else {
                            await this.paymentFailedUpdate(order.orderDocId);
                            this.loading.dismiss();
                            this.presentFailureAlert();
                        }
                    });
        } catch (error) {
            console.log(error);
        }
    }

    async payWithCashfree(order, txnRes, payableAmnt) {
        try {
            //await this.presentLoading();
            const paymentDetails = {
                order: order,
                mode: 'cashfree',
                txnRes: txnRes,
                amount: payableAmnt
            };
            console.log('paymentDetails from manual:', paymentDetails);
            let saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-saveOrderPaymentDetails');
            saveOrderPaymentDetails(paymentDetails).then(async (res) => {
                if (res.data.status && res.data.status === 'success') {
                    const paymentChatMsg = {
                        author: 'user',
                        createdAt: new Date(),
                        isRead: true,
                        orderId: order.orderId,
                        published: true,
                        status: 'PaymentMsg',
                        type: 'order',
                        paymentMode: 'cashfree'
                    }
                    await this.paymentChatMsgs(paymentChatMsg, order);

                    this.loading.dismiss();
                    this.presentAlert(this.labelService.labels['ORDER_SERVICE']['payment_is_successful']);
                } else {
                    this.loading.dismiss();
                    this.presentFailureAlert();
                }
            });


        } catch (error) {
            console.dir(error);
            error['location'] = 'order-service:payWithRazorPay';
            this.logglyService.log(error);
            this.loading.dismiss();
            this.presentFailureAlert();
        }
    }

    async createOrderCashfree(orderData, autoConfirm){
        return new Promise<any>(async (resolve) => {
            try {
                // await this.presentLoading();
                let orderCashfree;
                let payableAmount;
                if(autoConfirm) {
                    const {payableAmnt, order} = await this.ac_prepareOrderForPayment(orderData, 'cashfree');
                    payableAmount = payableAmnt;
                    console.log('order:', order);
                    orderCashfree = order;
                  } else {
                    const payableAmnt = orderData.partialPayment.status ? orderData.partialPayment.online.amount : (orderData.totalAmountToPaid - (orderData.walletAmount + orderData.cashbackAmount));
                    payableAmount = payableAmnt;
                    orderCashfree = orderData;
                  }
                const orderObj = {
                    orderAmnt: parseFloat(payableAmount.toFixed(2)),
                    userId: orderData.userId,
                    source: 'website-v2',
                    currencyCode: this.configService.environment.currencyCode,
                    user: {
                        id: orderData.userId,
                        email: this.userService.getUserEmail() || 'xyz@gmail.com',
                        phoneNo: this.userService.getPhoneNo() || ''
                    }

                }
                // let obj = {
                //     cashfreeOrder: orderDataCashfree,
                //     autoConfirm: autoConfirm,
                //     payableAmount: payableAmount
                // };
                // await this.afs.collection('payment').doc('cashfree').collection('users').doc(orderObj.userId).set(obj);
                let createOrderCashfree = firebase.functions().httpsCallable('payments-createOrderCashfree');
                createOrderCashfree(orderObj).then(async (res) => {
                        if (res.data) {
                            const cashfreeToken = res.data.order_token;
                            // this.loading.dismiss();
                            resolve({cashfreeToken, orderCashfree});
                        } else {
                            // this.loading.dismiss();
                            this.presentFailureAlert();
                            resolve({});
                        }
                }).catch((error) => {
                    // this.loading.dismiss();
                    this.presentFailureAlert();
                    resolve({});
                    console.log('err:::::::::::',error);
                  });
            } catch (error) {
                // this.loading.dismiss();
                this.presentFailureAlert();
                resolve({});
                console.log('err from catch:',error);
            }
        })
}

async getOrderDetailsCashfree(cashfreeOrderId, orderData, autoConfirm){
    try {
        await this.presentLoading();
        let obj = {
            cashfreeOrderId: cashfreeOrderId
        };
        let getOrderDetailsCashfree = firebase.functions().httpsCallable('payments-getOrderDetailsCashfree');
        getOrderDetailsCashfree(obj).then(async (res) => {
            console.log('res:', res);
                if (res.data) {
                    if (res.data.order_status == 'PAID') {
                        let txnRes = {
                            order_status: res.data.order_status,
                            cf_order_id: res.data.cf_order_id,
                            created_at: res.data.created_at,
                            order_id: res.data.order_id,
                            order_amount: res.data.order_amount,
                        }
                        if (autoConfirm) {
                            this.ac_payWithCashfree(orderData, txnRes, txnRes.order_amount);
                            console.log('successfully paid');
                        } else {
                            this.payWithCashfree(orderData, txnRes, txnRes.order_amount);
                        }
                    } else {
                        if (autoConfirm) {
                            await this.paymentFailedUpdate(orderData.orderDocId);
                        } else {
                            await this.paymentFailedUpdate(orderData.id);
                        }
                        //console.log('inside failed with orderId:', cashfreeOrder);
                        //await this.paymentFailedUpdate(cashfreeOrder.orderDocId);
                        this.loading.dismiss();
                        this.presentFailureAlert();
                        console.log('not paid');
                    }
                } else {
                    await this.paymentFailedUpdate(orderData.orderDocId);
                    this.loading.dismiss();
                    this.presentFailureAlert();
                }
        }).catch((error) => {
            console.log('err:::::::::::',error);
          });
    } catch (error) {
        console.log('error:', error);
    }
}

async cashfreePaymentFailed(autoConfirm, orderData){
    if (autoConfirm) {
        await this.paymentFailedUpdate(orderData.orderDocId);
    } else {
        await this.paymentFailedUpdate(orderData.id);
    }
}

    // *** CCAvenue ***

    async createOrderCCAvenue(ccOrder: any, ccAvenueObj: any) {
        try {
            let payableAmnt: any, order: any
            if (ccOrder.autoConfirmOrder) {
                let ac_Data = await this.ac_prepareOrderForPayment(ccOrder, 'ccAvenue')
                payableAmnt = ac_Data.payableAmnt
                order = ac_Data.order
            } else if (!ccOrder.autoConfirmOrder) {
                payableAmnt = ccOrder.partialPayment.status ? ccOrder.partialPayment.online.amount : (ccOrder.totalAmountToPaid - (ccOrder.walletAmount + ccOrder.cashbackAmount))
                order = ccOrder
                order['orderDocId'] = ccOrder.id
            }
            order['currencyCode'] = this.configService.environment.currencyCode
            let registeredDomain = new URL(window.location.href).hostname;
            console.log('registeredDomain : ', registeredDomain)
            let processCCAvenuePayload = firebase.functions().httpsCallable('payments-processCCAvenuePayload');
            const paymentPayloadState = await processCCAvenuePayload({orderData : order, credentials: ccAvenueObj, type: 'encrypt', amount: payableAmnt, source: 'website', registeredDomain})
            if (paymentPayloadState.data.status === 'success') {
                console.log('Success in payload processing! : ', paymentPayloadState)  
            } else {
                console.log('Error in payload processing! : ', paymentPayloadState)  
                return
            }
            let paymentPayload = {
                encRequest: paymentPayloadState.data.encryptedData,
                access_code: ccAvenueObj.accessCode
            }
            const form = this.document.createElement("form");
            form.method = "POST";
            form.target = "_self";
            form.action = "https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction";
            for (let prop in paymentPayload) {
                const input = this.document.createElement("input");
                input.type = "hidden";
                input.name = prop;
                input.value = paymentPayload[prop];
                form.append(input);
            }
            this.document.body.appendChild(form);
            form.submit();
        } catch (error) {
            console.log('error in createOrderCCAvenue : ', error)
        }
    }

    async checkCCAvenueOID(docId: any) {
        return new Promise( async (resolve, reject) => {
            try {
                const orderRef = this.afs.collection('orders').doc(docId)
                orderRef.get().subscribe(async (doc) => {
                    if (doc.exists) {
                        console.log('found order : ', doc.data())
                        resolve(doc.data())
                    } else {
                        console.log('not found!')
                        resolve({})
                    }
                });
            } catch (error) {
                console.log('error in checkCCAvenueOID!', error)
                resolve({})
            }
        })
    }

    async checkCCAvenueOrderStatusTracker(docId: any) {
        return new Promise( async (resolve, reject) => {
            try {
                let ccAvenueOrderStatusTracker = firebase.functions().httpsCallable('payments-ccAvenueOrderStatusTracker');
                const OSTData = await ccAvenueOrderStatusTracker({oid: docId})
                console.log('Response : ', OSTData.data)
                if (OSTData.data.status) {
                    resolve({
                        status: true
                    })
                } else {
                    resolve({
                        status: false
                    })
                }
            } catch (error) {
                console.log('error in checkCCAvenueOID! : ', error)
                resolve({
                    status: false
                })
            }
        })
    }

}