import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { DeliveryService } from 'src/app/services/delivery/delivery.service';
import { LabelService } from 'src/app/services/label/label.service';
declare var google;

@Component({
  selector: 'app-map',
  templateUrl: './map.page.html',
  styleUrls: ['./map.page.scss'],
})
export class MapPage implements OnInit {
  loading;
  SHARED_LABELS: any = {};
  @ViewChild('map',{ static: false}) mapElement: ElementRef;
  map: any;
  marker;
  // By default google headquarters
  lat:any = 37.419857;
  lng:any = -122.078827;
  constructor(private modalController: ModalController, private deliveryService: DeliveryService,
    private loadingController: LoadingController, private labelService: LabelService) {
    }

  ngOnInit() {
    this.SHARED_LABELS = this.labelService.labels['SHARED'];
    navigator.geolocation.getCurrentPosition((position) => {
      console.log('position:', position);
      this.lat = position.coords.latitude;
      this.lng = position.coords.longitude;
    setTimeout(() => {
      this.loadMap();
    }, 500);
    }, (error)=>{
      setTimeout(() => {
        this.loadMap();
      }, 500);
      console.log('error:', error);
    });
  }

  closeModal() {
    this.modalController.dismiss();
  }
  
  async presentLoading() {
    this.loading = await this.loadingController.create({
      message: this.SHARED_LABELS['please_wait'],
      duration: 10000
    });
    await this.loading.present();
  }

async loadMap(){
  console.log('lat:', this.lat, 'lng:', this.lng);
  let latLng = await new google.maps.LatLng(this.lat, this.lng);
  console.log('latLng obj:', latLng);
  let mapOptions = {
    center: latLng,
    zoom: 15,
    scrollwheel: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP
  }

  this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
  let marker = new google.maps.Marker({
    map: this.map,
    animation: google.maps.Animation.DROP,
    draggable:true,
    position: this.map.getCenter()
  });
  this.marker = marker;
  let content = `<p><b>Order will be delivered hare</b></p><p>Place the pin accurately on the map</p>`;
  let infoWindow = new google.maps.InfoWindow({
    content: content
  });
  infoWindow.open(this.map, marker);
  new google.maps.event.addListener(this.marker, 'dragend', function (event) {
  });
}

async saveAddress(){
  this.presentLoading();
  let latLngObj = {
    lat: this.marker.getPosition().lat(),
    lng: this.marker.getPosition().lng()
  };
    const address:any = await this.deliveryService.getAddressFromLatLng(latLngObj);
    const formatted_address = address.results[0].formatted_address;
      this.loading.dismiss();
      this.modalController.dismiss({address: formatted_address, location: {lat: latLngObj.lat, lng: latLngObj.lng}});
}

}
