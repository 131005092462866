import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { AlertController, LoadingController, Events, Platform, ModalController, NavParams } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user/user.service';
import { StatesModalPage } from '../states-modal/states-modal.page';
import { LabelService } from 'src/app/services/label/label.service';
import { ConfigService } from '../services/config/config.service';
import { MapPage } from './map/map.page';
import { MultiCountriesService } from '../services/multi-countries/multi-countries.service';
export class NewAddressPage {
    constructor(events, router, alertController, loadingController, route, userService, platform, navParams, labelService, modalController, configService, multiCountriesService) {
        this.events = events;
        this.router = router;
        this.alertController = alertController;
        this.loadingController = loadingController;
        this.route = route;
        this.userService = userService;
        this.platform = platform;
        this.navParams = navParams;
        this.labelService = labelService;
        this.modalController = modalController;
        this.configService = configService;
        this.multiCountriesService = multiCountriesService;
        this.addressObj = {
            name: '',
            address: '',
            city: '',
            state: '',
            stateCode: '',
            pincode: '',
            phoneNo: '',
            createdAt: null,
            defaultAddress: true,
            lat: null,
            lng: null,
            country: '',
            additionalPhoneNo: '',
        };
        this.routeFromCheckoutPage = false;
        this.routeFromSelectAddress = false;
        this.routeFromMyAddresses = false;
        this.states = [];
        this.addressType = 'shipping';
        this.SHARED_LABELS = {};
        this.NEW_ADDRESS_LABELS = {};
        this.countries = [];
        this.pageType = 'page';
        if (navParams.get('addressData')) {
            this.editAddressData = navParams.get('addressData');
        }
        if (navParams.get('addressLength')) {
            this.addressLength = navParams.get('addressLength');
        }
        if (navParams.get('routeFromCheckoutPage')) {
            this.routeFromCheckoutPage = navParams.get('routeFromCheckoutPage');
        }
        if (navParams.get('routeFromSelectAddress')) {
            this.routeFromSelectAddress = navParams.get('routeFromSelectAddress');
        }
        if (navParams.get('routeFromMyAddresses')) {
            this.routeFromMyAddresses = navParams.get('routeFromMyAddresses');
        }
        if (navParams.get('type')) {
            this.addressType = navParams.get('type');
        }
        this.geoCoder = new google.maps.Geocoder;
        console.log('routeFromSelectAddress', this.routeFromSelectAddress);
    }
    ngOnInit() { }
    ionViewDidEnter() {
        this.initializeSubscriptions();
        this.addressObj.phoneNo = this.userService.getPhoneNo();
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        this.NEW_ADDRESS_LABELS = this.labelService.labels['NEW_ADDRESS'];
        this.countries = this.multiCountriesService.multiCountriesSettings.countries;
    }
    ionViewDidLeave() {
        this.removeSubscriptions();
    }
    modalDismiss() {
        this.modalController.dismiss();
    }
    initializeSubscriptions() {
        this.events.subscribe('user:newAddressSaved', () => {
            this.loading.dismiss();
            if (this.routeFromCheckoutPage === true || this.routeFromSelectAddress === true) {
                this.modalDismiss();
                this.router.navigate(['order-summary']);
            }
            else {
                if (this.pageType === 'modal') {
                    this.modalController.dismiss({ close: false });
                }
                else {
                    this.presentAlert(`${this.NEW_ADDRESS_LABELS['address_saved_successfully']}`, true);
                }
            }
        });
        this.events.subscribe('user:addressEditSuccess', () => {
            this.loading.dismiss();
            this.modalDismiss();
            console.log('routeFromSelectAddress', this.routeFromSelectAddress);
            if (this.routeFromSelectAddress === true) {
                this.router.navigate(['order-summary']);
            }
            else {
                if (this.pageType === 'modal') {
                    this.modalController.dismiss({ close: false });
                }
                else {
                    this.presentAlert(`${this.NEW_ADDRESS_LABELS['address_edited_successfully']}`, true);
                }
            }
        });
        this.events.subscribe('user:errorInGettingAddress', () => {
            this.loading.dismiss();
            this.presentAlert(`${this.NEW_ADDRESS_LABELS['error_in_getting_address']}`);
        });
        this.events.subscribe('user:addressValueFromLatLng', (response) => {
            this.loading.dismiss();
            //// console.log('address', response);
            this.getAddressFromResponse(response.results[0]);
        });
    }
    getAddressFromResponse(data) {
        if (!this.editAddressData) {
            this.addressObj.address = data.formatted_address;
            this.addressFromApi = data.formatted_address;
            for (let index = 0; index < data.address_components.length; index++) {
                for (let x = 0; x < data.address_components[index].types.length; x++) {
                    if (data.address_components[index].types[x] === "postal_code") {
                        this.addressObj.pincode = data.address_components[index].long_name;
                    }
                }
            }
        }
        else {
            this.editAddressData.address = data.formatted_address;
            this.addressFromApi = data.formatted_address;
            for (let index = 0; index < data.address_components.length; index++) {
                for (let x = 0; x < data.address_components[index].types.length; x++) {
                    if (data.address_components[index].types[x] === "postal_code") {
                        this.editAddressData.pincode = data.address_components[index].long_name;
                    }
                }
            }
        }
    }
    onClickSaveAddress() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.addressObj.name = this.addressObj.name.trim();
            this.addressObj.city = this.addressObj.city.trim();
            if (this.addressObj.name === '') {
                this.presentAlert(`${this.NEW_ADDRESS_LABELS['please_enter_name']}`);
            }
            else if (this.addressObj.address === '') {
                this.presentAlert(`${this.NEW_ADDRESS_LABELS['please_enter_address']}`);
            }
            else if (this.addressObj.city === '') {
                this.presentAlert(`${this.NEW_ADDRESS_LABELS['please_enter_city']}`);
            }
            else if (this.addressObj.state === '') {
                this.presentAlert(`${this.NEW_ADDRESS_LABELS['please_enter_state']}`);
            }
            else if (this.addressObj.pincode === '') {
                this.presentAlert(`${this.NEW_ADDRESS_LABELS['please_enter_pincode']}`);
            }
            else if (this.addressObj.phoneNo === '') {
                this.presentAlert(`${this.NEW_ADDRESS_LABELS['please_enter_phoneno']}`);
            }
            else {
                yield this.presentLoading();
                this.addressObj.createdAt = new Date();
                if (this.addressFromApi === this.addressObj.address) {
                    this.addressObj.lat = this.latitude;
                    this.addressObj.lng = this.longitude;
                }
                this.events.publish('user:saveNewAddress', this.addressObj, this.addressType);
                //this.modalDismiss();
            }
        });
    }
    onClickEditAddress() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.editAddressData.name = this.editAddressData.name.trim();
            this.editAddressData.city = this.editAddressData.city.trim();
            if (this.editAddressData.name === '') {
                this.presentAlert(`${this.NEW_ADDRESS_LABELS['please_enter_name']}`);
            }
            else if (this.editAddressData.address === '') {
                this.presentAlert(`${this.NEW_ADDRESS_LABELS['please_enter_address']}`);
            }
            else if (this.editAddressData.city === '') {
                this.presentAlert(`${this.NEW_ADDRESS_LABELS['please_enter_city']}`);
            }
            else if (this.editAddressData.state === '') {
                this.presentAlert(`${this.NEW_ADDRESS_LABELS['please_enter_state']}`);
            }
            else if (this.editAddressData.pincode === '') {
                this.presentAlert(`${this.NEW_ADDRESS_LABELS['please_enter_pincode']}`);
            }
            else if (this.editAddressData.phoneNo === '') {
                this.presentAlert(`${this.NEW_ADDRESS_LABELS['please_enter_phoneno']}`);
            }
            else {
                yield this.presentLoading();
                this.editAddressData.createdAt = new Date();
                if (this.addressFromApi === this.editAddressData.address) {
                    this.editAddressData.lat = this.latitude;
                    this.editAddressData.lng = this.longitude;
                }
                this.events.publish('user:editSavedAddress', this.editAddressData, this.addressType);
            }
        });
    }
    updateNewAddressDefaultStatus() {
        if (this.addressObj.defaultAddress === true || this.addressObj.defaultAddress === null) {
            this.addressObj.defaultAddress = false;
        }
        else {
            this.addressObj.defaultAddress = true;
        }
    }
    updateEditAddressDefaultStatus(status) {
        if (status === true) {
            this.editAddressData.status = false;
        }
        else {
            this.editAddressData.status = true;
        }
    }
    goToPage(page) {
        this.router.navigate([page]);
    }
    addressChange() {
        this.editAddressData.lat = null;
        this.editAddressData.lng = null;
    }
    openStateModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalController.create({
                component: StatesModalPage,
                cssClass: 'custom-modal'
            });
            modal.onDidDismiss()
                .then((res) => {
                //// console.log('data from modal', res);
                if (res.data) {
                    //// console.log(res.data);
                    if (!this.editAddressData) {
                        this.addressObj.state = res.data.state;
                        this.addressObj.stateCode = res.data.code;
                    }
                    else {
                        this.editAddressData.state = res.data.state;
                        this.editAddressData.stateCode = res.data.code;
                    }
                }
            });
            yield modal.present();
        });
    }
    presentAlert(msg, action) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                message: msg,
                buttons: [{
                        text: 'Ok',
                        handler: () => {
                            //// console.log('Confirm Okay');
                            if (action === true) {
                                this.router.navigate(['user-addresses']);
                                this.modalDismiss();
                            }
                        }
                    }]
            });
            yield alert.present();
        });
    }
    presentLoading() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = yield this.loadingController.create({
                message: this.SHARED_LABELS['please_wait'],
                duration: 10000
            });
            yield this.loading.present();
        });
    }
    isPincodeValid() {
        const pincode = this.editAddressData ? this.editAddressData.pincode : this.addressObj.pincode;
        if (this.configService.environment.currencyCode === 'INR' && pincode.length && pincode.length < 6) {
            return false;
        }
        else {
            return true;
        }
    }
    getPincodeHeading() {
        if (this.configService.environment.currencyCode === 'INR') {
            return 'NEW_ADDRESS.pincode';
        }
        else {
            return 'NEW_ADDRESS.state_area_code';
        }
    }
    closeModal() {
        this.modalController.dismiss({ closed: true });
    }
    removeSubscriptions() {
        this.events.unsubscribe('user:newAddressSaved');
        this.events.unsubscribe('user:addressEditSuccess');
        this.events.unsubscribe('user:errorInGettingAddress');
        this.events.unsubscribe('user:addressValueFromLatLng');
    }
    showMapModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //this.askLocationPermission();
            const modal = yield this.modalController.create({
                component: MapPage,
                cssClass: 'custom-modal'
            });
            modal.onDidDismiss()
                .then((res) => {
                if (res.data) {
                    if (!this.editAddressData) {
                        console.log('address:', res.data);
                        this.addressObj.address = res.data.address;
                        this.addressObj.lat = res.data.location.lat;
                        this.addressObj.lng = res.data.location.lng;
                    }
                    else if (this.editAddressData) {
                        this.editAddressData.address = res.data.address;
                        this.editAddressData.lat = res.data.location.lat;
                        this.editAddressData.lng = res.data.location.lng;
                    }
                }
            });
            yield modal.present();
        });
    }
    // askLocationPermission(){
    //   navigator.geolocation.getCurrentPosition((position) => {
    //     console.log('position:', position);
    //     // this.lat = position.coords.latitude;
    //     // this.lng = position.coords.longitude;
    //     // setTimeout(() => {
    //     //   this.loadMap();
    //     // }, 500);
    //   })
    // }
    onChangeCountry(e) {
        this[this.editAddressData ? 'editAddressData' : 'addressObj'].country = e.target.value;
    }
    phoneNumberValidation(event, type) {
        const pattern = /[0-9.,+]/;
        let inputChar = String.fromCharCode(event.charCode);
        if (type === 'new') {
            if (!pattern.test(inputChar) || this.addressObj.phoneNo.length >= 13) {
                event.preventDefault();
            }
        }
        else if (type === 'edit') {
            if (!pattern.test(inputChar) || this.editAddressData.phoneNo.length >= 13) {
                event.preventDefault();
            }
        }
    }
    textValidation(event) {
        const pattern = /[a-zA-Z]+$/;
        let inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    }
    getPincodeData(event) {
        // const Code= this.multiCountriesService.getMultiCountries();
        var country_Code = '';
        var pincode = "";
        var country = this[this.editAddressData ? 'editAddressData' : 'addressObj'].country;
        pincode = this.editAddressData ? this.editAddressData.pincode : this.addressObj.pincode;
        // console.log(pincode);
        let res = this.countries.forEach(element => {
            if (element.countryName === country) {
                // console.log(element.countryCode);
                country_Code = element.countryCode;
                // console.log(country_Code);
            }
        });
        // var res=this.multiCountriesService.getCountryCode(stat);
        // res.then(d =>{
        //   // console.log(d);
        let data = fetch(`https://api.worldpostallocations.com?postalcode=${pincode}&countrycode=${country_Code}`);
        data.then(res => res.json()).then(g => {
            try {
                // this.isPincodeValid(pincode);
                if (!this.editAddressData) {
                    this.addressObj.state = g.result[0].state;
                    this.addressObj.stateCode = g.result[0].stateId;
                    if (g.result[0].province != "NA") {
                        this.addressObj.city = g.result[0].province;
                    }
                    else {
                        this.addressObj.city = g.result[0].district;
                    }
                }
                else {
                    this.editAddressData.state = g.result[0].state;
                    this.editAddressData.stateCode = g.result[0].stateId;
                    if (g.result[0].province != "NA") {
                        this.editAddressData.city = g.result[0].province;
                    }
                    else {
                        this.editAddressData.city = g.result[0].district;
                    }
                }
            }
            catch (e) {
                // console.log("invalid Pincode");
                // console.log(e);
                // this.isPincodeValid();
            }
        });
        // });
        // console.log(pincode);
        // var upper=country_Code.toUpperCase();
        // console.log(country_Code);
    }
    getLocationUsingPinCode(pincode) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log("pincode", pincode);
            console.log("this.isPinCodeValid()", this.isPincodeValid());
            if (this.isPincodeValid()) {
                return new Promise((resolve, reject) => {
                    this.geoCoder.geocode({ 'address': pincode }, (results, status) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        if (status == google.maps.GeocoderStatus.OK) {
                            let lat = results[0].geometry.location.lat();
                            let lng = results[0].geometry.location.lng();
                            console.log("lat, lng", lat, lng);
                            const state = yield this.getGeoCodedAddress(lat, lng);
                            console.log("state", state);
                            if (state) {
                                yield this.setLocationInAddress(state);
                            }
                            resolve(true);
                        }
                        else {
                            console.log('Geocoder error!');
                            resolve(false);
                        }
                    }));
                });
            }
        });
    }
    getGeoCodedAddress(lat, lng) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let city, state, country;
            if (navigator.geolocation) {
                const latLng = yield new google.maps.LatLng(lat, lng);
                const request = { latLng: latLng };
                yield new Promise((resolve) => {
                    this.geoCoder.geocode(request, (results, status) => {
                        if (status === google.maps.GeocoderStatus.OK) {
                            const result = results[0];
                            const resultAddressComponent = result.address_components;
                            console.log("resultAddressComponent", resultAddressComponent);
                            for (let address of resultAddressComponent) {
                                for (let addressTypes of address.types) {
                                    if (addressTypes === 'locality') {
                                        console.log("City locality: ", address.long_name);
                                        city = address.long_name;
                                    }
                                    if (addressTypes === 'administrative_area_level_1') {
                                        console.log("State administrative_area_level_1: ", address.long_name);
                                        state = address.long_name;
                                    }
                                    if (addressTypes === 'country') {
                                        console.log("Country: ", address.long_name);
                                        country = address.long_name;
                                    }
                                }
                            }
                            if (result !== null) {
                                resolve(true);
                            }
                            else {
                                alert('Unable to get your precise location.');
                                resolve(false);
                            }
                        }
                        else {
                            console.log('Geocoder error!');
                            resolve(false);
                        }
                    });
                });
            }
            return { state, city, country };
        });
    }
    setLocationInAddress(state) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.editAddressData) {
                this.addressObj.city = state.city || '';
                this.addressObj.state = state.state || '';
                this.addressObj.country = state.country || '';
            }
            else {
                this.editAddressData.city = state.city || '';
                this.editAddressData.state = state.state || '';
                this.editAddressData.country = state.country || '';
            }
        });
    }
}
