import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { DeliveryService } from 'src/app/services/delivery/delivery.service';
import { LabelService } from 'src/app/services/label/label.service';
export class MapPage {
    constructor(modalController, deliveryService, loadingController, labelService) {
        this.modalController = modalController;
        this.deliveryService = deliveryService;
        this.loadingController = loadingController;
        this.labelService = labelService;
        this.SHARED_LABELS = {};
        // By default google headquarters
        this.lat = 37.419857;
        this.lng = -122.078827;
    }
    ngOnInit() {
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        navigator.geolocation.getCurrentPosition((position) => {
            console.log('position:', position);
            this.lat = position.coords.latitude;
            this.lng = position.coords.longitude;
            setTimeout(() => {
                this.loadMap();
            }, 500);
        }, (error) => {
            setTimeout(() => {
                this.loadMap();
            }, 500);
            console.log('error:', error);
        });
    }
    closeModal() {
        this.modalController.dismiss();
    }
    presentLoading() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = yield this.loadingController.create({
                message: this.SHARED_LABELS['please_wait'],
                duration: 10000
            });
            yield this.loading.present();
        });
    }
    loadMap() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log('lat:', this.lat, 'lng:', this.lng);
            let latLng = yield new google.maps.LatLng(this.lat, this.lng);
            console.log('latLng obj:', latLng);
            let mapOptions = {
                center: latLng,
                zoom: 15,
                scrollwheel: false,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            };
            this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
            let marker = new google.maps.Marker({
                map: this.map,
                animation: google.maps.Animation.DROP,
                draggable: true,
                position: this.map.getCenter()
            });
            this.marker = marker;
            let content = `<p><b>Order will be delivered hare</b></p><p>Place the pin accurately on the map</p>`;
            let infoWindow = new google.maps.InfoWindow({
                content: content
            });
            infoWindow.open(this.map, marker);
            new google.maps.event.addListener(this.marker, 'dragend', function (event) {
            });
        });
    }
    saveAddress() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.presentLoading();
            let latLngObj = {
                lat: this.marker.getPosition().lat(),
                lng: this.marker.getPosition().lng()
            };
            const address = yield this.deliveryService.getAddressFromLatLng(latLngObj);
            const formatted_address = address.results[0].formatted_address;
            this.loading.dismiss();
            this.modalController.dismiss({ address: formatted_address, location: { lat: latLngObj.lat, lng: latLngObj.lng } });
        });
    }
}
